<template>
  <div>
    <el-switch
      v-model="form.status"
      :active-value="true"
      :inactive-value="false"
      :disabled="!allowAccessFor(['admin', 'operations'])"
      @change="showConfirm = true"
    >
    </el-switch>
    <eden-confirm-dialog
      :show.sync="showConfirm"
      :title="`${status ? 'Hide' : 'Show'} on Garden`"
      :button-type="`${status ? 'danger' : 'success'}`"
      :button-text="`${status ? 'Hide' : 'Show'}`"
      :button-status="updating"
      :close-on-click-modal="false"
      @confirm="changeStatus"
      @cancel="cancel"
    >
      <p>
        Are you sure you want to
        {{ status ? "hide" : "show" }} selected meal(s) on Garden?
      </p>
    </eden-confirm-dialog>
  </div>
</template>

<script>
import meal from "@/requests/services/meal";

export default {
  name: "MealItemGardenStatus",
  props: {
    status: {
      type: Boolean,
      required: true,
    },
    ids: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      form: {
        status: true,
      },
      showConfirm: false,
      updating: false,
    };
  },
  computed: {
    setStatus: {
      get() {
        return this.status;
      },
      set(value) {
        this.$emit("update:status", value);
      },
    },
  },
  created() {
    this.form.status = this.status;
  },
  methods: {
    cancel() {
      this.showConfirm = false;
      this.form.status = this.status;
    },
    changeStatus() {
      this.updating = true;
      const payload = {
        activation_status: this.status ? "deactivate" : "activate",
        service_type_ids: this.ids,
      };
      meal
        .status(payload)
        .then((response) => {
          if (response.data.status) {
            this.showConfirm = false;
            this.$emit("success");
            this.setStatus = this.form.status;
            this.updating = false;
          }
        })
        .catch((error) => {
          this.updating = false;
          this.$message.error(error.response.data.message);
        });
    },
  },
};
</script>
